//Dev

// export const baseUrl = "http://vitaranlive.com:3301/admin/";  

//Prod
export const baseUrl = "http://vitaranlaabh.com:4304/admin/";   

// export const baseUrl = "http://localhost:3301/admin/";

export const BaseImgUrl =
  "https://selorce.s3.ap-south-1.amazonaws.com/selorce-dev/";
export const ShopBaseImgUrl =
  "https://saathi-prod.s3.ap-south-1.amazonaws.com/shops/";
