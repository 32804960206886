
import useAxiosPrivate from ".././useAxiosPrivate";
import { toast } from "react-toastify";
const useUpdateDemoDeliveryBoy = () => {
  // console.log(form);
  const Axios = useAxiosPrivate();

  const updateDemoDeliveryBoy = async (param, areaData, dropdownData) => {
    // Create a shallow copy of the param object
    const updatedParam = { ...param };

    // Add or update properties in the copy
    updatedParam.shape_id = areaData?.selectedShapeId;
    updatedParam.zone_id = dropdownData?.zone_id;
    const response = await Axios.post("/edit_demo_delivery_boy", updatedParam);
    const data = await response.data;
    if (data.code === 200) {
      toast.success("Updated Successfully!");
    } else if (data.code === 400) {
      toast.error(data.message);
    } else {
      toast.error("Failed to Update");
    }
  };
  return updateDemoDeliveryBoy;
};

export default useUpdateDemoDeliveryBoy;
