import React, { useState, useEffect } from "react";
import styles from "./styles/midContainerUser.module.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { styled } from "@mui/material/styles";

import UserMgtTable from "./UserMgtTable";

import { useSelector, useDispatch } from "react-redux";
import UserMgtAsmUpdate from "./UserMgtAsmUpdate";

import exportFromJSON from "export-from-json";
import AddDeliveryBoyForm from "./AddDeliveryBoyForm";
import useAddDeliveryBoy from "../../hooks/useAddDeliveryPartner";

import useRefreshUserList from "../../hooks/useRefreshUserList";
import useUserList from "../../hooks/useUserList";
import useLaabhDELPartner from "../../hooks/useLaabhDeliveryPart";
import { TabContext, TabList } from "@mui/lab";
import { Tab } from "@mui/material";
import useDemoDbList from "../../hooks/DemoDeliveryBoy/useDemoDbList";
import useAddDemoDeliveryBoy from "../../hooks/DemoDeliveryBoy/useAddDemoDeliveryBoy";
const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

const MidContainerUserMgt = ({ asmZone }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.login?.refreshToken?.user_data);
  const [selectedAreaName, setSelectedAreaName] = useState("ALL");
  const [selectedStatus, setSelectedStatus] = useState("ALL");
   const [areaData, setAreaData] = useState({
      selectedArea: [],
      selectedShapeId: [],
    });
  // const [zonelist, setZonelist] = React.useState([]);
  const [RoleList, setRoleList] = useState(["All", "ASM", "TSI", "SE"]);
  const [StatusList, setStatusList] = useState([
    "All",
    "ACTIVE",
    "INACTIVE",
    "PENDING",
    "REJECT",
  ]);
  //   const Firsttoken = useSelector((state) => state.Token);
  const [Userdetail, setUserDetail] = useState({});
  const [q, setQ] = useState("");
  const [Role, setRole] = React.useState("All");
  const [Status, setStatus] = React.useState("All");
  const [value, setValue] = React.useState("1");
  const [searchColumn, setSearchColumn] = useState([
    "user_id",
    "user_code",
    "name",
    "surname",
    "mobile_no",
  ]);
  const [filterlist, setFilterlist] = useState([]);
  const userAllList = useSelector((store) => store.user.userList);
  //   const list = useSelector((state) => state.Zone.list);
  //   const userlist = useSelector((state) => state.UserMgt);
  const [token, setToken] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dropDownData = useSelector((state) => state.app.curretDropdownData);
  const areaNameList = useSelector((store) => store.user.areaNameList);
  const userStatusList = useSelector((store) => store.user.userStatusList);
  const addDeliveryBoy = useAddDeliveryBoy();
  const addDemoDeliveryBoy = useAddDemoDeliveryBoy()
  const  [selectedShapeId, setSelectedShapeId] = useState("");
  const getMapDBlist = useLaabhDELPartner();
  const currentzone = useSelector((state) => state.SFstatus.zone_id);
  const getAllUserList=useUserList(selectedStatus,selectedAreaName);
  const getdemoDbList = useDemoDbList(selectedStatus,selectedAreaName, selectedShapeId)
  const handleAddDeliveryBoy = async (formData) => {
    if(value === 1 || value ==="1"){
      await addDeliveryBoy(formData);
      await getAllUserList();
      const param = {
        zone_id: dropDownData?.zone_id
      }
      await getMapDBlist(param);
    }else{
      await addDemoDeliveryBoy(formData, areaData);
      await getdemoDbList();
      const param = {
        zone_id: dropDownData?.zone_id
      }
      await getMapDBlist(param);
    }
   
  };

  useEffect(() => {
    if(value === "1"){
      getAllUserList();
    }else{
      getdemoDbList()
    }
  }, [currentzone, selectedStatus, selectedAreaName, value]);

  // const handleStatusChange = (event) => {
  //   setStatus(event.target.value);
  // };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const fileName = "ASM/TSI/SE";
  const exportType = "csv";
  const handleDownload = () => {
    let data = filterlist;

    exportFromJSON({ data, fileName, exportType });
  };

  const [query, setQuery] = useState("");
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };
  const [filterUserList, setFilterUserList] = useState(null);

  useEffect(() => {
    if (userAllList) {
      setFilterUserList(userAllList);
    }
  }, [userAllList]);
  useEffect(() => {
    const filteredData = userAllList?.filter((user) => {
      const full_name = `${user?.first_name} ${user?.last_name}`.toLowerCase();
      const areaNamesTitle = user?.shape_details
                        ?.map((shape) => shape?.area_name)
                        .join(", ");
      return (
        full_name?.toLowerCase().includes(query?.toLowerCase()) ||
        user?.mobile_no?.toLowerCase().includes(query?.toLowerCase()) ||
        user?.user_role_name?.toLowerCase().includes(query?.toLowerCase()) ||
        user?.zone_name?.toLowerCase().includes(query?.toLowerCase()) ||
        user?.bank_name?.toLowerCase().includes(query?.toLowerCase()) ||
        areaNamesTitle?.toLowerCase().includes(query?.toLowerCase())
      );
    });

    setFilterUserList(filteredData);
  }, [query]);

  const handleStatusChange = (e) => {
    if (e.target.value === "ALL") {
      setSelectedAreaName("ALL");
    } else if (selectedStatus === "ALL") {
      setSelectedAreaName("");
    }
    setSelectedStatus(e.target.value);
  };
  
  const handleAreaNameChange = (e) => {
    if (e.target.value === "ALL") {
      setSelectedStatus("ALL");
    } else if (selectedAreaName === "ALL") {
      setSelectedStatus("");
    }
    setSelectedAreaName(e.target.value);
    const selectedValue = areaNameList.find(
      (value) => value.area_name === e.target.value
    );
    setSelectedShapeId(selectedValue?.shape_id);
  };

  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div
        className={styles.MidContainer}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className={styles.MidContainerOne}>
          <div className={styles.MidSubContainerOne}>
            <div
              className={styles.MidSubContainerOneList}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <label className={styles.MidSubContainerOneLabel}>
                Search User
              </label>
              <input
                variant="outlined"
                // sx={{ minWidth: 200}}
                // size="small"
                value={query}
                onChange={handleSearch}
                placeholder="search...."
                style={{ height: "1.8rem" }}
              />
            </div>
            <TabContext value={value}>
                <Box
                  sx={{
                    border: "1px solid",
                    borderColor: "divider",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "5px",
                    width: "30%",
                    alignSelf: "center",
                    marginTop:"-0.1rem",
                    // maxWidth:"fit-content"
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    indicatorColor="#000"
                    sx={{
                      width: "95%",
                      "& .Mui-selected": {
                        backgroundColor: "#DC1D13",
                        color: "#ffffff !important",
                        border: "none",
                        borderRadius: "4px",
                        margin: "4px",
                        minHeight: "15px",
                      },
                      "& .MuiTabs-root": {
                        minHeight: "30px", // Height for the entire TabList
                      },
                      "& .MuiTabIndicator-root": {
                        display: "none !important", // This will hide the blue bottom line
                      },
                    }}
                  >
                    <Tab
                      label="Partner"
                      value="1"
                      sx={{
                        minHeight: "30px !important",
                        height: "20px !important",
                        margin: "4px",
                        width: "50%",
                      }}
                    />
                    <Tab
                      label="Demo Partner"
                      value="2"
                      sx={{
                        minHeight: "30px !important",
                        height: "20px !important",
                        margin: "4px",
                        width: "50%",
                      }}
                    />
                    
                  </TabList>
                </Box>
              </TabContext>
            <div className={styles.MidSubContainerOneList}>
              <label className={styles.MidSubContainerOneLabel}>Status</label>
              <div className={styles.MidSubContainerOneList}>
                <Box sx={{ minWidth: 170 }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="shape-id-status-label"
                      id="shape-id-status-select"
                      value={selectedStatus}
                      size="small"
                      sx={{ height: "1.8rem" }}
                      onChange={(e) => handleStatusChange(e)}
                    >
                      {userStatusList?.map((status) => (
                        <MenuItem
                          key={status.user_status}
                          value={status.user_status}
                        >
                          {status.user_status?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <label className={styles.MidSubContainerOneLabel}>Area Name</label>
              <Box sx={{ minWidth: 170 }}>
                <FormControl fullWidth>
                  <Select
                    labelId="area-name-label"
                    id="area-name-id-select"
                    value={selectedAreaName}
                    size="small"
                    sx={{ height: "1.8rem" }}
                    onChange={(e) => handleAreaNameChange(e)}
                  >
                    {areaNameList?.map((areaName) => (
                      <MenuItem
                        key={areaName?.area_name}
                        value={areaName?.area_name}
                      >
                        {areaName?.area_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            
            
          </div>

          <div className={styles.MidSubContainerTwo}>

            <RedButton
              variant="contained"
              onClick={() => setIsDialogOpen(true)}
              sx={{ textTransform: "none" }}
              disabled={userData?.demo}
            >
              Add
            </RedButton>

            <AddDeliveryBoyForm
              open={isDialogOpen}
              onClose={() => setIsDialogOpen(false)}
              onSubmit={handleAddDeliveryBoy}
              value={value}
              areaNameList = {areaNameList}
              areaData = {areaData}
              setAreaData={setAreaData}
            />
          </div>
        </div>
        <div className={styles.MidContainerTwo}>
          <UserMgtTable
            filterlist={filterUserList}
            detail={
              filterlist &&
              filterlist.length !== 0 &&
              filterlist !== "undefined"
                ? Userdetail
                : {}
            }
            query={query}
            setUserDetail={setUserDetail}
          />
        </div>
      </div>

      <div id="AsmUserUpdate">
        <div>
          <div>
            <div>
              <div className={styles.UserMgtBottomContainer}>
                {/* {filterlist &&
                filterlist.length !== 0 &&
                filterlist !== "undefined" ? ( */}
                <UserMgtAsmUpdate
                  asmZone={asmZone}
                  detail={
                    filterlist &&
                    filterlist.length !== 0 &&
                    filterlist !== "undefined"
                      ? Userdetail
                      : {}
                  }
                  list={
                    filterlist &&
                    filterlist.length !== 0 &&
                    filterlist !== "undefined"
                      ? filterlist
                      : []
                  }
                  getAllUserList={getAllUserList}
                  value = {value}
                  getDemoDbList={getdemoDbList}
                />
                {/* ) : (
                  <h5>No Data</h5>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MidContainerUserMgt;
