import  React from "react";
import Button from "@mui/material/Button";


import AddLaabhPartner from "../component/UserManagement.js/AddLaabhPartner";
import useAddLaabhPartner from "../hooks/useAddLaabhPartner";
import useLaabhPartner from "../hooks/useLaabhPartners";
import useAddDemoLaabhPartner from "../hooks/DemoPartner/useAddDemoLaabhPartner";


export default function BasicModal( {getAllDistributorList, selectedZoneName, selectedZoneId, value, getDemoLpList, areaNameList, areaData, setAreaData}) {

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  // console.log(value, "value1111")
  
  const postAddLaabhPartner = useAddLaabhPartner();
  const getmapLaabhPartnerList = useLaabhPartner(); 
  const addDemoLaabhPartner = useAddDemoLaabhPartner();
  const handleAddDeliveryBoy = async(formData) => {
    if(value === "1"){
      await postAddLaabhPartner(formData);
      await getAllDistributorList();
      const param = {
        zone_id: formData.zone_id
      }
      await getmapLaabhPartnerList(param);
    }else{
      await addDemoLaabhPartner(formData, areaData);
      await getDemoLpList();
      const param = {
        zone_id: formData.zone_id
      }
      await getmapLaabhPartnerList(param);
    }
  };


  return (
    <div>
      <Button
        onClick={() => setIsDialogOpen(true)}
        sx={{ color: "white", textTransform: "none" }}
      >
        Add
      </Button>
      <AddLaabhPartner
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSubmit={handleAddDeliveryBoy}
        selectedZoneName={selectedZoneName}
        selectedZoneId={selectedZoneId}
        value={value}
        areaNameList={areaNameList}
        areaData = {areaData}
        setAreaData = {setAreaData}
      />
    </div>
  );
}
