import { useEffect } from "react";
import useAxiosPrivate from ".././useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import {
  getAreaNameList,
  getDistributorList,
  getStateList,
  getUserStatusList,
  getZoneList,
} from "../../slice/distributorSlice";
import { getUserList } from "../../slice/userSlice";

const useDemoDbList = (
  selectedStatus, selectedAreaName, selectedShapeId
) => {
  const Axios = useAxiosPrivate();
  const currentzone = useSelector((state) => state.SFstatus.zone_id);
  const dispatch = useDispatch();
  const getdemoDbList = async () => {
    const param = {
      zone_id: currentzone,
      user_status: selectedStatus,
      area_name: selectedAreaName,
      shape_id: selectedShapeId
    };
    const response = await Axios.post("/demo_delivery_boy_list", param);
    const data = await response.data;
   dispatch(getUserList(data.body));
   dispatch(getAreaNameList(data.areaNameList));
   dispatch(getUserStatusList(data.userStatusList));
  };
//   useEffect(() => {
//     getdemoLpList();
//   }, [selectedZoneId, selectedStatus, selectedAreaName, selectedStateId]);

  return getdemoDbList;
};

export default useDemoDbList;
