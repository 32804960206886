import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import {
  getAreaNameList,
  getUserList,
  getUserStatusList,
} from "../slice/userSlice";

const useUserList = (selectedStatus, selectedAreaName) => {
  const currentzone = useSelector((state) => state.SFstatus.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllUserList = async () => {
    if (currentzone) {
      const param = {
        zone_id: currentzone,
        user_status: selectedStatus,
        area_name: selectedAreaName,
      };
      const response = await Axios.post("/delivery_boy_list", param);
      const data = await response.data;
      dispatch(getUserList(data.body));
      dispatch(getAreaNameList(data.areaNameList));
      dispatch(getUserStatusList(data.userStatusList));
    }
  };
  // useEffect(() => {
  //   getAllUserList();
  // }, [currentzone, selectedStatus, selectedAreaName]);

  return getAllUserList;
};

export default useUserList;
