import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, Paper, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import AddInventory from "./AddInventory";
import { ToastContainer } from "react-toastify";
const InventoryTable = ({ query, dropDownData }) => {
  const inventoryData = useSelector((state) => state.sku.inventoryList);
  const userData = useSelector((state) => state?.login?.refreshToken?.user_data);
  const getInventoryStatus = (data) => {
    if (data.inventory === null || data.alert_qty === null) {
      return "Out of Stock";
    } else if (data.inventory > data.alert_qty) {
      return "In Stock";
    }else if (data.inventory === 0) {
      return "Out of Stock";
    } else if (data.inventory <= data.alert_qty) {
      const text = `${data?.inventory} pcs left`;
      return text;
    } 
  };
  const filteredData = inventoryData?.filter((rowData) =>
    rowData.sku_name.toLowerCase().includes(query.toLowerCase())
  );
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleOpen = (rowData) => {
    const updatedRowData = {
      ...rowData,
      shape_id: dropDownData.shape_id,
      category_name: dropDownData.category_name,
      category_id: dropDownData.category_id,
      office_name: dropDownData.office_name,
      pincode: dropDownData.pincode,
    };
    setSelectedRow(updatedRowData);
    setOpen(true);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // 24-hour format
    });
  };
  
  const handleClose = () => setOpen(false);
  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Stack
        sx={{
          backgroundColor: "#F8F8F8",
          //   paddingTop: "10px",
          //   paddingLeft: {xs:"16px", md:"32px"},
          //   paddingRight: {xs:"16px", md:"32px"},
          // paddingBottom: "32px",
        }}
      >
        <TableContainer
          style={{ height: "calc(100vh - 200px)", overflowY: "auto" }}
          elevation={0}
          component={Paper}
        >
          <Table sx={{ border: "1px solid #E8E8E8" }}>
            <TableHead
              sx={{
                color: "#9D9D9D",
                fontSize: "14px",
                fontWeight: "400",
                textAlign: "center",
                position: "sticky",
                top: 0,
                backgroundColor: "#F8F8F8", // Set background color as needed
                zIndex: 1,
              }}
            >
              <TableRow style={{ height: "20px" }}>
                <TableCell
                  sx={{
                    color: "#9D9D9D",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "none",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    color: "#9D9D9D",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "none",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    color: "#9D9D9D",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "none",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    color: "#9D9D9D",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "none",
                  }}
                ></TableCell>
                <TableCell
                  colSpan={3}
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    border: "none",
                    fontWeight: "400",
                    textAlign: "center",
                    borderRadius: "0px 0px 20px 20px",
                    backgroundColor: "#DBDBDB",
                    padding: "0px",
                  }}
                >
                  Quantity Last Added
                </TableCell>

                <TableCell
                  sx={{
                    color: "#9D9D9D",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "none",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    color: "#9D9D9D",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "none",
                  }}
                ></TableCell>
              </TableRow>

              {/* Second row for individual column headings */}
              <TableRow>
                <TableCell
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  Image
                </TableCell>
                <TableCell
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  SKU Name
                </TableCell>
                <TableCell
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  Inventory (In Pieces )
                </TableCell>
                <TableCell
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "8px",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  Pieces 
                </TableCell>
                <TableCell
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  Date
                </TableCell>
                <TableCell
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  Alert pieces
                </TableCell>
                <TableCell
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "8px",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData?.map((rowData, index) => (
                <React.Fragment key={index}>
                  <TableRow
                    sx={{ fontSize: "16px", fontWeight: "400" }}
                    key={index}
                  >
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={rowData?.sku_image}
                        alt="SKU image"
                        style={{ width: "32px", height: "32px" }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        textAlign: "center",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        cursor: "pointer",
                        maxWidth: "100px",
                        overflow: "hidden",
                      }}
                      title={rowData?.sku_name}
                    >
                      {rowData?.sku_name}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        textAlign: "center",
                        backgroundColor: "rgba(220, 29, 19, 0.05)",
                      }}
                    >
                      {rowData?.inventory}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        textAlign: "center",
                        backgroundColor: "rgba(220, 29, 19, 0.05)",
                      }}
                    >
                      {rowData?.last_updated_qty}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        textAlign: "center",
                        backgroundColor: "rgba(220, 29, 19, 0.05)",
                      }}
                    >
                      {rowData?.qty_updated_at
                        ? formatDate(rowData?.qty_updated_at)
                        : "- -"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        textAlign: "center",
                        backgroundColor: "rgba(220, 29, 19, 0.05)",
                      }}
                    >
                      {rowData?.alert_qty}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{
                          backgroundColor:
                            getInventoryStatus(rowData) === "Out of Stock"
                              ? "#E50000"
                              : getInventoryStatus(rowData) === "In Stock"
                              ? "#307F00"
                              : "#E58900",
                          color: "#fff",
                          padding: "4px 8px 4px 8px",
                          borderRadius: "100px",
                          fontSize: "14px",
                        }}
                      >
                        {getInventoryStatus(rowData)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        disabled={userData?.demo}
                        sx={{
                          color: "#DC1D13 !important",
                          fontWeight: "700",
                          backgroundColor: "white !important",
                          "&:hover": {
                            backgroundColor: "#DC1D13 !important",
                            color: "white !important",
                            border: "2px solid #DC1D13 !important",
                          },
                          border: "2px solid #DC1D13 !important",
                          padding: "14px 12px 14px 12px !important",
                          alignItems: "center",
                          height: "30px !important",
                          fontSize: "14px !important",
                          borderRadius: "4px !important",
                          textTransform: "none",
                        }}
                        onClick={() => handleOpen(rowData)}
                      >
                        <AddIcon fontSize="small" />
                        Add Stock
                      </Button>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <AddInventory
        open={open}
        handleClose={handleClose}
        inputValues={selectedRow}
      />
    </div>
  );
};

export default InventoryTable;
