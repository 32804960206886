import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
  ListItemText,
  MenuItem,
  InputLabel,
  Select,
  FormControl
} from "@mui/material";
import { useSelector } from "react-redux";
import useSuperCategoryList from "../../hooks/useSuperCategoryList";
import { toast } from "react-toastify";

const AddLaabhPartner = ({ open, onClose, onSubmit, selectedZoneName, selectedZoneId, value, areaNameList, areaData, setAreaData }) => {
  const getDropDownData = useSelector((state) => state.app.curretDropdownData);

  const [formData, setFormData] = useState({
    name: "",
    zone_id: selectedZoneId,
    zone_name: selectedZoneName,
    mobile_no: "",
    email: "",
    // sc_id: [],
  });

  const [selectedScId, setSelectedScId] = useState([]);
  
  const [errors, setErrors] = useState({});

  const handleAreaChange = (e)=>{
    const selectedValue = e.target.value;

    const selectedIds = selectedValue?.map((selectedArea) => {
      const foundArea = areaNameList?.find(
        (area) => area.area_name === selectedArea
      );
      return foundArea ? foundArea?.shape_id : null;
    });
    
    setAreaData((prev)=>({
      ...prev,
      selectedArea: selectedValue,
      selectedShapeId: selectedIds?.filter((shape_id)=> shape_id!=null)
    }))
  }

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      zone_id: selectedZoneId,
      zone_name: selectedZoneName
    }));
  }, [selectedZoneId, open]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      sc_id: selectedScId,
    }));
  }, [selectedScId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateMobileNumber = () => {
    const mobileNumber = formData.mobile_no;

    // Check if the mobile number is a string of 10 digits
    if (mobileNumber && mobileNumber.length === 10 && !isNaN(mobileNumber)) {
      return true;
    }

    return false;
  };

  const validateEmail = () => {
    const email = formData.email;

    if (
      email &&
      email.includes("@") &&
      email.split("@").length === 2 &&
      email.split("@")[1].includes(".") &&
      email.trim() !== ""
    ) {
      return true;
    }

    return false;
  };

  const handleFormSubmit = () => {
    const newErrors = {};

    // Validate name
    if (!formData.name?.trim()) {
      newErrors.name = "Name is required";
    }

    if (!formData.email?.trim()) {
      newErrors.email = "Email is required";
    } else if (!validateEmail()) {
      newErrors.email = "Invalid Email Address";
    }

    // Validate mobile_no
    if (!formData?.mobile_no?.trim()) {
      newErrors.mobile_no = "Mobile Number is required";
    } else if (!validateMobileNumber()) {
      newErrors.mobile_no = "Invalid Mobile Number";
    }

    // If there are errors, setErrors and return
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }


    if(selectedZoneName === "ALL"){
      toast.error("Please Select Zone other than ALL!")
      return;
    }

    // If no errors, submit the form
    onSubmit(formData);
    onClose();
    setFormData({});
    setErrors({});
    setSelectedScId([]);
    setAreaData({
      selectedArea: [],
      selectedShapeId: []
    })
  };

  const handleClose = () => {
    onClose();
    setFormData({
      name: "",
      zone_id: getDropDownData.zone_id,
      mobile_no: "",
      sc_id: [],
    });
    setErrors({});
    setSelectedScId([]);
    setAreaData({
      selectedArea: [],
      selectedShapeId: []
    })
  };

  useSuperCategoryList();

  const superCategoryList = useSelector(
    (state) => state.superCategory.superCategoryList
  );

  const handleSCChange = (e, scId) => {
    let updatedScId = [...selectedScId];

    if (e.target.checked) {
      // If the checkbox is checked, add the scId to the array
      updatedScId.push(scId);
    } else {
      // If the checkbox is unchecked, filter out the scId from the array
      updatedScId = updatedScId.filter((sc) => sc !== scId);
    }

    setSelectedScId(updatedScId); // Update the state with the new array
  };
  return (
    <Dialog
      maxWidth={"800px"}
      open={open}
      onClose={onClose}
      sx={{ width: "50vw", margin: "auto" }}
    >
      {value === "1" ? (
        <DialogTitle>Add Distribution Partner</DialogTitle>
      ) : (
        <DialogTitle>Add Demo Distribution Partner</DialogTitle>
      )}

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "40vw",
          maxWidth: "100%",
        }}
      >
        <label htmlFor="name">Name:</label>
        <TextField
          size="small"
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          fullWidth
          required
          error={Boolean(errors.name)}
          helperText={errors.name}
        />
        <label htmlFor="name">Email:</label>
        <TextField
          size="small"
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          fullWidth
          required
          error={Boolean(errors.email)}
          helperText={errors.email}
        />
        <label htmlFor="zone_id">Zone Name:</label>
        <TextField
          size="small"
          type="text"
          id="zone_id"
          name="zone_id"
          value={formData?.zone_name}
          onChange={handleInputChange}
          fullWidth
          disabled
        />

        <label htmlFor="mobile_no">Mobile Number:</label>
        <TextField
          size="small"
          type="text"
          id="mobile_no"
          name="mobile_no"
          value={formData.mobile_no}
          onChange={handleInputChange}
          fullWidth
          required
          error={Boolean(errors.mobile_no)}
          helperText={errors.mobile_no}
        />
        {value === 2 ||
          (value === "2" && (
            <>
              <label htmlFor="mobile_no">Area Name:</label>
              <FormControl sx={{ minWidth: 250 }}>
                {/* <InputLabel sx={{ top: "-10px", color: "#989797" }}>
            Area Name
          </InputLabel> */}
                <Select
                  labelId="shape-id-label"
                  id="shape-id-select"
                  name="sku_id"
                  value={areaData?.selectedArea}
                  size="small"
                  renderValue={(selected) => {
                    if (selected.length > 2) {
                      return `${selected.slice(0, 2).join(", ")}...`;
                    }
                    return selected.join(", ");
                  }}
                  // renderValue={() => null}
                  multiple
                  sx={{ height: "2.2rem" }}
                  onChange={(e) => handleAreaChange(e)}
                >
                  {/* <MenuItem
              value="All"
              key={0}
              sx={{
                padding: "2px",
                marginTop: "-8px",
              }}
            >
              {" "}
              <Checkbox
                checked={areaNameList
                  // ?.filter((category) => category.status === true)
                  ?.every((area) => areaData?.selectedShapeId?.includes(area.shape_id))}
              />
              <ListItemText
                primary={<span style={{ fontWeight: "bold" }}>Select All</span>}
              />
            </MenuItem> */}
                  {areaNameList &&
                    areaNameList
                      ?.filter((area) => area.area_name != "ALL")
                      .map((area) => (
                        <MenuItem
                          key={area?.shape_id}
                          value={area?.area_name}
                          sx={{
                            padding: "2px",
                            marginTop: "-8px",
                          }}
                        >
                          <Checkbox
                            checked={
                              areaData?.selectedArea?.indexOf(
                                area?.area_name
                              ) >= 0
                            }
                          />
                          <ListItemText primary={area?.area_name} />
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </>
          ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            handleClose();
          }}
          sx={{ color: "gray" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleFormSubmit}
          variant="contained"
          sx={{
            backgroundColor: "#DC1D13",
            ":hover": {
              backgroundColor: "#960c08",
            },
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddLaabhPartner;
