import { useEffect } from "react";
import useAxiosPrivate from ".././useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import {
  getAreaNameList,
  getDistributorList,
  getStateList,
  getUserStatusList,
  getZoneList,
} from "../../slice/distributorSlice";

const useDemoLpList = (
  selectedStatus,
  selectedStateId,
  selectedZoneId,
  selectedAreaName,
  selectedShapeId
) => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getdemoLpList = async () => {
    const param = {
      zone_id: selectedZoneId === 0 ? "ALL" : selectedZoneId,
      state_id: selectedStateId === 0 ? "ALL" : selectedStateId,
      user_status: selectedStatus,
      area_name: selectedAreaName,
      shape_id: selectedShapeId,
    };
    const response = await Axios.post("/demo_laabh_partner_list", param);
    const data = await response.data;
    dispatch(getDistributorList(data.body));
    dispatch(getStateList(data.stateNameList));
    dispatch(getZoneList(data.zoneNameList));
    dispatch(getAreaNameList(data.areaNameList));
    dispatch(getUserStatusList(data.userStatusList));
  };
//   useEffect(() => {
//     getdemoLpList();
//   }, [selectedZoneId, selectedStatus, selectedAreaName, selectedStateId]);

  return getdemoLpList;
};

export default useDemoLpList;
