
import useAxiosPrivate from ".././useAxiosPrivate";
import { toast } from "react-toastify";
const useAddDemoLaabhPartner = () => {
  // console.log(form);
  const Axios = useAxiosPrivate();

  const addPartner = async (param, areaData) => {
    param.shape_id = areaData?.selectedShapeId
    const response = await Axios.post("/add_demo_laabh_partner", param);
    const data = await response.data;
    if (data.status === 200) {
      toast.success(data.message);
    } else if (data.status === 400) {
      toast.error(data.message);
    } else {
      toast.error("Failed to Add");
    }
  };
  return addPartner;
};

export default useAddDemoLaabhPartner;
