import * as React from "react";
import Paper from "@mui/material/Paper";
import {
  Stack,
  Typography,
  Button,
  FormHelperText,
  Box,
  Switch,
  Checkbox
} from "@mui/material";
// import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { useDispatch, useSelector } from "react-redux";
import EditNoteTwoToneIcon from "@mui/icons-material/EditNoteTwoTone";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useInventorySkuList from "../../hooks/useInventorySkuList";
import CloseIcon from "@mui/icons-material/Close";
import useUpdateInventory from "../../hooks/useupdateInventory";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ToastContainer } from "react-toastify";
import useGetShapeIdList from "../../hooks/useGetShapeIdList";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { getShapeIdList } from "../../slice/appSlice";
import { getSelectedAreaName, getSelectedCategory, getSelectedSF } from "../../slice/skuSlice";
import useUpdateInventorySkuStatus from "../../hooks/useUpdateInventorySkuStaus";
import useUpdateInventoryV2 from "../../hooks/useUpdateInventoryV2";
import useUpdateInventorySuggest from "../../hooks/useUpdateInventorySuggest";
import useUpdateOutofStockStatus from "../../hooks/useUpdateOutofStockStatus";
export default function SuperCategoryManagementTable() {
  const dispatch = useDispatch();
  const InventorySKUList = useSelector((store) => store.sku.inventorySkuList);
  const zonedata = useSelector((store) => store.app.curretDropdownData);
  const SFId = useSelector((store) => store.sku.selectedSF);
  const BoundryDetails = useSelector(
    (state) => state.adminMap.getBoundryDetails
  );
  const userData = useSelector(
    (state) => state?.login?.refreshToken?.user_data
  );
  const getSkuList = useInventorySkuList();
  const updateStatus = useUpdateInventorySkuStatus();
  const updateAutoSuggest = useUpdateInventorySuggest();
  // useSuperCategoryList();
  const [page, setPage] = React.useState(0);
  const [shapeID, setSelectedshapeID] = React.useState("");
  const [rowData, setRowData] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [error, setError] = React.useState({});
  const [switchStatus, setSwitchStatus] = React.useState(null);
  const [formDataList, setFormDataList] = React.useState([]);
  const [switchStates, setSwitchStates] = React.useState([]);
  const [autoSuggestStates, setAutoSuggestStates] = React.useState([]);
  const [outofStockStates, setOutofStockStates] = React.useState([]);
  const [editModeIndex, setEditModeIndex] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  // Your existing state and effect hooks

  const handleEditClick = (index, data) => {
    setEditModeIndex(index === editModeIndex ? null : index);
    setRowData(data);
    // setIsEdit(true);
  };
  const handleClose = () => {
    setEditModeIndex(null);
  };
  // console.log(outofStockStates, "outofStockStates");
  React.useEffect(() => {
    if (BoundryDetails && BoundryDetails.sf_details) {
      const shapeDetail = BoundryDetails.sf_details.find(
        (detail) => detail && detail.shape_id === SFId
      );
      if (shapeDetail) {
        setSwitchStatus(shapeDetail.shape_file_status);
      } else {
        console.log("Shape ID not found");
      }
    } else {
      console.log("BoundryDetails or sf_details is undefined");
    }
  }, [SFId, BoundryDetails]);

  React.useEffect(() => {
    if (InventorySKUList) {
      const outOfStockValues = InventorySKUList.map(data => data.out_of_stock ?? false);
      setAutoSuggestStates(InventorySKUList.map(data => data.auto_suggest));
      setOutofStockStates(outOfStockValues);
    }
  }, [InventorySKUList]);

  React.useEffect(() => {
    if (InventorySKUList) {
      setSwitchStates(InventorySKUList?.map((data) => data.status));
    }
  }, [InventorySKUList]);

  const getAllshapeIdList = useGetShapeIdList();

  const handleSwitchChange = async (data, index) => {
    const newSwitchStates = [...switchStates];
    newSwitchStates[index] = !newSwitchStates[index];
    setSwitchStates(newSwitchStates);
    // Make API call here with the updated status
    const updatedStatus = newSwitchStates[index];
    // const skuId = skuList[index].sku_id;
    const param = {
      sku_id: data?.sku_id,
      shape_id: SFId,
      status: updatedStatus,
    };
    await updateStatus(param);
  };
  const handleAutoSuggestChange = async(index) => {
    const newAutoSuggestStates = [...autoSuggestStates];
    newAutoSuggestStates[index] = !newAutoSuggestStates[index];
    setAutoSuggestStates(newAutoSuggestStates);
    // Make API call here with the updated status
    const updatedStatus = newAutoSuggestStates[index]; // The updated status of the row
    const si_Id = InventorySKUList[index].si_id; // Assuming there's a unique identifier for each row, like sku_id
    const param = {
      si_id: si_Id,
      auto_suggest: updatedStatus,
    };
    await updateAutoSuggest(param);
  };
  const updateOOS = useUpdateOutofStockStatus();
  const handleStockStatusChange = async(sku_id, isChecked) => {
    
    const param = {
      si_id:sku_id,
      status: isChecked
    }
    await updateOOS(param);
    const param2 = {
      category_id: selectedCategory,
      shape_id: shapeID,
    };
    await getSkuList(param2);
    // console.log(sku_id, isChecked, "sku_id");
  };

  // React.useEffect(() => {
  //   const param = {
  //     zone_id: zonedata?.zone_id,
  //   };

  //   getAllshapeIdList(param);
  // }, [zonedata]);

  // const handleInputChange = (index, fieldName, value, sku_id, si_id) => {
  //   if ((value === "") | null) {
  //     const previousValue = InventorySKUList[index][fieldName];
  //     const updatedRowData = [...rowData];
  //     updatedRowData[index] = {
  //       ...updatedRowData[index],
  //       [fieldName]: parseFloat(previousValue),
  //       sku_id: sku_id,
  //       shape_id: shapeID,
  //       si_id: si_id,
  //     };
  //     setRowData(updatedRowData);
  //   } else {
  //     const updatedRowData = [...rowData];
  //     updatedRowData[index] = {
  //       ...updatedRowData[index],
  //       [fieldName]: parseFloat(value),
  //       sku_id: sku_id,
  //       shape_id: shapeID,
  //       si_id: si_id,
  //     };
  //     setRowData(updatedRowData);
  //   }
  //   setFormDataList((prevFormDataList) => {
  //     const updatedFormDataList = [...prevFormDataList];
  //     updatedFormDataList[index] = {
  //       ...updatedFormDataList[index],
  //       [fieldName]: value,
  //     };
  //     return updatedFormDataList;
  //   });
  // };
  const handleSlabChange = (e, index) => {
    const { name, value } = e.target;

    // Create a copy of rowData
    const updatedRowData = { ...rowData };

    // Create a copy of the slab_data array and update the specific slab object
    updatedRowData.slab_data = rowData.slab_data.map((slab, i) => {
      if (i === index) {
        return { ...slab, [name]: value }; // Update the specific slab object
      }
      return slab;
    });

    // Update state with the modified rowData
    setRowData(updatedRowData);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "category_id") {
      const selectedCategory = categoryList.find(
        (category) => category.category_id === value
      );
      if (selectedCategory) {
        setRowData((prevData) => ({
          ...prevData,
          category_id: value,
          category_name: selectedCategory.category_name, // Update category_name based on selected category_id
        }));
      }
    } else {
      setRowData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const [selectedCategory, setSelectedCategory] = React.useState("");

  const isValid = () => {
    let success = true;
    let err = {};
    if (
      rowData.sku_name?.trim() === "" ||
      rowData.sku_name?.trim() === undefined ||
      rowData.sku_name?.trim() === null
    ) {
      err.sku_name = "**SKU Name is compulsory**";
      success = false;
    }
    if (
      rowData.hindi_name?.trim() === "" ||
      rowData.hindi_name?.trim() === undefined ||
      rowData.hindi_name?.trim() === null
    ) {
      err.hindi_name = "**Hindi Name is compulsory**";
      success = false;
    }
    if (
      rowData.gujarati_name?.trim() === "" ||
      rowData.gujarati_name?.trim() === undefined ||
      rowData.gujarati_name?.trim() === null
    ) {
      err.gujarati_name = "**Gujarati Name is compulsory**";
      success = false;
    }
    // Add validation for slabs
    if (parseInt(rowData.toOne) <= parseInt(rowData.fromOne)) {
      err.toOne = "To value must be > From value";
      success = false;
    }

    if (parseInt(rowData.toTwo) <= parseInt(rowData.fromTwo)) {
      err.toTwo = "To value must be > From value";
      success = false;
    }

    if (parseInt(rowData.toThree) <= parseInt(rowData.fromThree)) {
      err.toThree = "To value must be > From value";
      success = false;
    }

    if (parseInt(rowData.fromTwo) <= parseInt(rowData.toOne)) {
      err.fromTwo = "From value must be > to value in Slab 1";
      success = false;
    }

    if (parseInt(rowData.fromThree) <= parseInt(rowData.toTwo)) {
      err.fromThree = "From value must be > to value in Slab 2";
      success = false;
    }

    rowData.slab_data.forEach((slab, index) => {
      // Check if slab quantity start is greater than previous slab quantity end
      if (
        index > 0 &&
        parseInt(slab.slab_qty_start) <=
          parseInt(rowData.slab_data[index - 1].slab_qty_end)
      ) {
        err[
          `slab_qty_start_${index}`
        ] = `From value must be > to value in Slab ${index + 1}`;
        success = false;
      }

      // Check if slab quantity start is less than slab quantity end
      if (
        slab.slab_qty_end &&
        parseInt(slab.slab_qty_start) >= parseInt(slab.slab_qty_end)
      ) {
        err[`slab_qty_end_${index}`] = "To value must be > From value";
        success = false;
      }
    });
    setError(err);
    return success;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const categoryList = useSelector((state) => state.category.categoryList);
  const shapeFileList = useSelector((state) => state.adminMap.getBoundryDetails);
  React.useEffect(() => {
    // Set selected category to the first item in categoryList
    if (categoryList && categoryList.length > 0) {
      setSelectedCategory(categoryList[0].category_id);
      
      dispatch(getSelectedCategory(categoryList[0].category_id));
    }
  }, [categoryList]);
  React.useEffect(() => {
    // Set shapeID to the shape_id of the first item in shapeFileList.sf_details
    if (shapeFileList?.sf_details?.length > 0) {
      setSelectedshapeID(shapeFileList.sf_details[0].shape_id);
      dispatch(getSelectedSF(shapeFileList.sf_details[0].shape_id));
      let text = shapeFileList?.sf_details[0]?.office_name + "|" + shapeFileList?.sf_details[0]?.pincode;
      dispatch(getSelectedAreaName(text));
    }
  }, [shapeFileList]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // React.useEffect(() => {
  //   console.log(rowData, "11111");
  // }, [rowData]);

  React.useEffect(() => {
    if (selectedCategory) {
      const param = {
        category_id: selectedCategory,
        shape_id: shapeID,
      };
      getSkuList(param);
    }
  }, [selectedCategory, shapeID]);

  const handleCategoryChange = (data) => {
    setSelectedCategory(data);
    dispatch(getSelectedCategory(data));
    setFormDataList((prevFormDataList) =>
      prevFormDataList?.map(() => ({
        inventory: "",
        ptr: "",
        mrp: "",
      }))
    );
  };

  const handleSFChange = (data) => {
    let text = null;
    for (let i = 0; i < shapeFileList?.sf_details?.length; i++) {
      if (data === shapeFileList?.sf_details[i]?.shape_id) {
        text = shapeFileList?.sf_details[i]?.office_name + "|" + shapeFileList?.sf_details[i]?.pincode;
        break;
      }
    }
    setSelectedshapeID(data);
    dispatch(getSelectedSF(data));
    dispatch(getSelectedAreaName(text))
    setFormDataList((prevFormDataList) =>
      prevFormDataList?.map(() => ({
        inventory: "",
        ptr: "",
        mrp: "",
      }))
    );
  };

  const updateInventory = useUpdateInventoryV2();
  const handleSubmit = async () => {
    console.log(rowData, "rowdata");
    // if (isValid()) {
    //   await updateInventory(rowData);
    //   if (selectedCategory) {
    //     const param = {
    //       category_id: selectedCategory,
    //       shape_id: shapeID,
    //     };
    //     await getSkuList(param);
    //   }
    // // }
    // setFormDataList(prevFormDataList =>
    //   prevFormDataList.map(() => ({
    //     inventory: '',
    //     ptr: '',
    //     mrp: ''
    //   }))
    // );
  };

  const handleSkuUpdate = async () => {
    if (isValid()) {
      await updateInventory(rowData);
      const param = {
        category_id: selectedCategory,
        shape_id: shapeID,
      };
      await getSkuList(param);

      setIsEdit(false);
      setEditModeIndex(null);
    }
  };
  const handleRadioChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "option1") {
      setRowData((prevData) => ({ ...prevData, enable_slab: true }));
    } else if (selectedValue === "option2") {
      setRowData((prevData) => ({ ...prevData, enable_slab: false }));
    }
  };
  const handleaddSlab = (e) => {
    const { name, value } = e.target;
    setRowData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Stack>
      <Stack flexDirection="row" gap={2}>
        <Stack
          sx={{
            gap: "4px",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "8px 6px",
          }}
        >
          <label>Category:</label>
          <FormControl
            sx={{ height: "1.8rem", width: "140px" }}
            error={Boolean(error.scheme)}
          >
            <Select
              labelId="category-label"
              id="category-select"
              value={selectedCategory}
              size="small"
              sx={{ height: "1.8rem" }}
              onChange={(e) => handleCategoryChange(e.target.value)}
            >
              {categoryList?.map(
                (category) =>
                  category.status && (
                    <MenuItem
                      key={category.category_id}
                      value={category.category_id}
                    >
                      {category.category_name}
                    </MenuItem>
                  )
              )}
            </Select>
            {error.scheme && <FormHelperText>{error.scheme}</FormHelperText>}
          </FormControl>
        </Stack>
        <Stack
          sx={{
            gap: "4px",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "8px 6px",
          }}
        >
          <label>Area:</label>
          <FormControl
            sx={{ height: "1.8rem", width: "200px" }}
            error={Boolean(error.sku_name)}
          >
            <Select
              labelId="shape-id-label"
              id="shape-id-select"
              value={shapeID}
              size="small"
              sx={{ height: "1.8rem" }}
              onChange={(e) => handleSFChange(e.target.value)}
            >
              {shapeFileList?.sf_details?.map((sf, index) => (
                <MenuItem key={index} value={sf?.shape_id}>
                  {`${sf?.office_name} | ${sf?.pincode}`}
                </MenuItem>
              ))}
            </Select>
            {error.sku_name && (
              <FormHelperText>{error.sku_name}</FormHelperText>
            )}
          </FormControl>
        </Stack>
        <Stack sx={{ flexDirection: "row", alignItems: "center" }} gap={1}>
          SF Status :
          {switchStatus === true ? (
            <Typography
              sx={{
                background: "#6EB443",
                width: "max-content",
                height: "max-content",
                padding: "2px 10px 2px 12px",
                borderRadius: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#FFFFFF",
                fontSize: "9px",
                fontWeight: "400",
              }}
            >
              Active
            </Typography>
          ) : (
            <Typography
              sx={{
                background: "#58585A",
                width: "max-content",
                padding: "2px 10px 2px 12px",
                borderRadius: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#FFFFFF",
                fontSize: "9px",
                fontWeight: "400",
              }}
            >
              Inactive
            </Typography>
          )}
        </Stack>
      </Stack>

      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 480 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    background: "#F8F8F8",
                    color: "#5F6868",
                    fontWeight: "500",
                    fontSize: "14px",
                    // padding: "16px 10px",
                  }}
                  align="start"
                >
                  SI No.
                </TableCell>
                {/* <TableCell
                  sx={{
                    background: "#F8F8F8",
                    fontSize: "16px",
                    color: "#5F6868",
                    fontWeight: "500",
                    fontSize: "14px",
                    padding: "16px 6px",
                  }}
                  align="start"
                >
                  Out of Stock
                </TableCell> */}
                <TableCell
                  sx={{
                    background: "#F8F8F8",
                    fontSize: "16px",
                    color: "#5F6868",
                    fontWeight: "500",
                    fontSize: "14px",
                    // padding: "16px 6px",
                  }}
                  align="start"
                >
                  Image
                </TableCell>
                <TableCell
                  sx={{
                    background: "#F8F8F8",
                    fontSize: "16px",
                    color: "#5F6868",
                    fontWeight: "500",
                    fontSize: "14px",
                    // padding: "16px 6px",
                  }}
                  align="start"
                >
                  SKU Name
                </TableCell>
                <TableCell
                  sx={{
                    background: "#F8F8F8",
                    fontSize: "16px",
                    color: "#5F6868",
                    fontWeight: "500",
                    fontSize: "14px",
                    // padding: "16px 6px",
                  }}
                  align="start"
                >
                  Category
                </TableCell>
                <TableCell
                  sx={{
                    background: "#F8F8F8",
                    fontSize: "16px",
                    color: "#5F6868",
                    fontWeight: "500",
                    fontSize: "14px",
                    // padding: "16px 6px",
                  }}
                  align="center"
                  colSpan={2}
                >
                  PTR
                </TableCell>
                <TableCell
                  sx={{
                    background: "#F8F8F8",
                    fontSize: "16px",
                    color: "#5F6868",
                    fontWeight: "500",
                    fontSize: "14px",
                    // padding: "16px 6px",
                  }}
                  align="start"
                  colSpan={1}
                >
                  MRP
                </TableCell>
                {/* <TableCell
                  sx={{
                    background: "#F8F8F8",
                    fontSize: "16px",
                    color: "#5F6868",
                    fontWeight: "500",
                  }}
                  align="start"
                  colSpan={1}
                >
                  Inventory
                </TableCell> */}
                <TableCell
                  sx={{
                    background: "#F8F8F8",
                    fontSize: "16px",
                    color: "#5F6868",
                    fontWeight: "500",
                    fontSize: "14px",
                    // padding: "16px 6px",
                  }}
                  align="start"
                  colSpan={1}
                >
                  Scheme
                </TableCell>
                <TableCell
                  sx={{
                    background: "#F8F8F8",
                    fontSize: "16px",
                    color: "#5F6868",
                    fontWeight: "500",
                    fontSize: "14px",
                    // padding: "16px 6px",
                  }}
                  align="start"
                  colSpan={1}
                >
                  Scope
                </TableCell>
                <TableCell
                  sx={{
                    background: "#F8F8F8",
                    fontSize: "16px",
                    color: "#5F6868",
                    fontWeight: "500",
                    fontSize: "14px",
                    // padding: "16px 6px",
                  }}
                  align="start"
                  colSpan={1}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    background: "#F8F8F8",
                    fontSize: "16px",
                    color: "#5F6868",
                    fontWeight: "500",
                    fontSize: "14px",
                    // padding: "16px 6px",
                  }}
                  align="start"
                  colSpan={1}
                >
                  Suggested
                </TableCell>
                <TableCell
                  sx={{
                    background: "#F8F8F8",
                    fontSize: "16px",
                    color: "#5F6868",
                    fontWeight: "500",
                    fontSize: "14px",
                    // padding: "16px 6px",
                  }}
                  align="start"
                  colSpan={1}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {InventorySKUList?.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((data, index) => {
                const serialNumber = page * rowsPerPage + index + 1;
                const dataIndex = page * rowsPerPage + index;
                return (
                  <>
                    <TableRow hover role="checkbox" tabIndex={-1} key={data.id}>
                      <TableCell align="start">{serialNumber}</TableCell>
                      {/* <TableCell align="start">
                        <Checkbox
                          sx={{ color: "#1C1B1F" }}
                          color="default"
                          checked={data?.out_of_stock}
                          onChange={(event) => handleStockStatusChange(data.si_id, event.target.checked)}
                        />
                      </TableCell> */}
                      <TableCell align="start">
                        <img style={{ width: "3rem" }} src={data?.sku_image} />
                      </TableCell>
                      <TableCell align="start">
                        <Stack gap={1}>
                          {editModeIndex === index ? (
                            <>
                              <TextField
                                label="English"
                                size="small"
                                name="sku_name"
                                error={error.sku_name}
                                helperText={error.sku_name}
                                value={rowData?.sku_name || ""}
                                onChange={handleInputChange}
                              />
                              <TextField
                                label="Hindi"
                                size="small"
                                name="hindi_name"
                                error={error.hindi_name}
                                helperText={error.hindi_name}
                                value={rowData?.hindi_name || ""}
                                onChange={handleInputChange}
                              />
                              <TextField
                                label="Gujarati"
                                size="small"
                                name="gujarati_name"
                                error={error.gujarati_name}
                                helperText={error.gujarati_name}
                                value={rowData?.gujarati_name || ""}
                                onChange={handleInputChange}
                              />
                            </>
                          ) : (
                            <>
                              <div>{data?.sku_name}</div>
                              <div>{data?.hindi_name}</div>
                              <div>{data?.gujarati_name}</div>
                            </>
                          )}
                        </Stack>
                      </TableCell>
                      <TableCell>
                        {editModeIndex === index ? (
                          <FormControl
                            sx={{ height: "1.8rem", width: "140px" }}
                            error={Boolean(error.scheme)}
                          >
                            <Select
                              name="category_id"
                              labelId="category-label"
                              id="category-select"
                              size="small"
                              sx={{ height: "1.8rem" }}
                              onChange={handleInputChange}
                              value={rowData.category_id || ""}
                            >
                              {categoryList?.map(
                                (category) =>
                                  category.status && (
                                    <MenuItem
                                      key={category.category_id}
                                      value={category.category_id}
                                    >
                                      {category.category_name}
                                    </MenuItem>
                                  )
                              )}
                            </Select>
                            {error.scheme && (
                              <FormHelperText>{error.scheme}</FormHelperText>
                            )}
                          </FormControl>
                        ) : (
                          data.category_name
                        )}
                      </TableCell>
                      {data?.si_id === null || rowData.slab_data?.length < 3 ? (
                        <TableCell
                          align="start"
                          colSpan={2}
                          sx={{ maxWidth: "180px", minWidth: "220px" }}
                        >
                          <div>
                            {editModeIndex === index ? (
                              <Stack
                                spacing={2}
                                flexDirection="row"
                                alignItems="center"
                              >
                                <Stack alignItems="center">
                                  <Box
                                    gap={1}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      margin: "4px",
                                      gap: "4px",
                                      alignItems: "center",
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Stack flexDirection="row">
                                      <Radio
                                        checked={rowData.enable_slab}
                                        onChange={handleRadioChange}
                                        value="option1"
                                        sx={{
                                          height: "18px",
                                          alignSelf: "center",
                                        }}
                                      />
                                      <Stack gap={1}>
                                        <Stack flexDirection="row" gap={1}>
                                          <TextField
                                            size="small"
                                            name="fromOne"
                                            label="from"
                                            value={rowData?.fromOne}
                                            onChange={handleaddSlab}
                                          />
                                          <TextField
                                            size="small"
                                            name="toOne"
                                            label="to"
                                            error={error.toOne}
                                            // helperText={error.toOne}
                                            value={rowData?.toOne}
                                            onChange={handleaddSlab}
                                          />
                                          <TextField
                                            size="small"
                                            name="priceOne"
                                            label=""
                                            value={rowData?.priceOne}
                                            onChange={handleaddSlab}
                                          />
                                        </Stack>
                                        <p
                                          style={{
                                            padding: "0px",
                                            margin: "0px",
                                            fontSize: "12px",
                                            color: "red",
                                          }}
                                        >
                                          {error.toOne}
                                        </p>
                                        <Stack flexDirection="row" gap={1}>
                                          <TextField
                                            size="small"
                                            name="fromTwo"
                                            label="from"
                                            value={rowData?.fromTwo}
                                            onChange={handleaddSlab}
                                          />
                                          <TextField
                                            size="small"
                                            name="toTwo"
                                            label="to"
                                            value={rowData?.toTwo}
                                            onChange={handleaddSlab}
                                          />
                                          <TextField
                                            size="small"
                                            name="priceTwo"
                                            label=""
                                            value={rowData?.priceTwo}
                                            onChange={handleaddSlab}
                                          />
                                        </Stack>
                                        <p
                                          style={{
                                            padding: "0px",
                                            margin: "0px",
                                            fontSize: "12px",
                                            color: "red",
                                          }}
                                        >
                                          {error.fromTwo
                                            ? `${error.fromTwo}`
                                            : ""}
                                          {error.fromTwo && error.toTwo
                                            ? ", "
                                            : ""}
                                          {error.toTwo ? `${error.toTwo}` : ""}
                                        </p>
                                        <Stack flexDirection="row" gap={1}>
                                          <TextField
                                            size="small"
                                            name="fromThree"
                                            label="from"
                                            value={rowData?.fromThree}
                                            onChange={handleaddSlab}
                                          />
                                          <TextField
                                            size="small"
                                            name="toThree"
                                            label="to"
                                            value={rowData?.toThree}
                                            onChange={handleaddSlab}
                                          />
                                          <TextField
                                            size="small"
                                            name="priceThree"
                                            label=""
                                            value={rowData?.priceThree}
                                            onChange={handleaddSlab}
                                          />
                                        </Stack>
                                        <p
                                          style={{
                                            padding: "0px",
                                            margin: "0px",
                                            fontSize: "12px",
                                            color: "red",
                                          }}
                                        >
                                          {error.fromThree
                                            ? `${error.fromThree}`
                                            : ""}
                                          {error.fromThree && error.toThree
                                            ? ", "
                                            : ""}
                                          {error.toThree
                                            ? `${error.toThree}`
                                            : ""}
                                        </p>
                                      </Stack>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                      <Radio
                                        checked={!rowData.enable_slab}
                                        onChange={handleRadioChange}
                                        value="option2"
                                      />
                                      <TextField
                                        size="small"
                                        name="ptr"
                                        type="integer"
                                        value={rowData?.ptr || ""}
                                        onChange={handleInputChange}
                                      />
                                    </Stack>
                                  </Box>
                                </Stack>
                              </Stack>
                            ) : (
                              <>
                                {switchStatus ? (
                                  <Stack
                                    sx={{
                                      textAlign: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {data?.enable_slab
                                      ? data.slab_data.map((slab, idx) => (
                                          <div key={idx}>
                                            {slab.slab_qty_start} -{" "}
                                            {slab.slab_qty_end} = {slab.slab_sp}
                                            ,
                                          </div>
                                        ))
                                      : data?.ptr}
                                  </Stack>
                                ) : (
                                  <Stack
                                    sx={{
                                      textAlign: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {data?.sku_ptr}{" "}
                                  </Stack>
                                )}
                              </>
                            )}
                          </div>
                        </TableCell>
                      ) : (
                        <TableCell
                          colSpan={2}
                          sx={{ maxWidth: "180px", minWidth: "220px" }}
                        >
                          <Stack gap={2} alignItems="center">
                            <div>
                              {editModeIndex === index ? (
                                <Stack
                                  spacing={2}
                                  flexDirection="row"
                                  alignItems="center"
                                >
                                  <Radio
                                    checked={rowData.enable_slab}
                                    onChange={handleRadioChange}
                                    value="option1"
                                    sx={{ height: "18px", alignSelf: "center" }}
                                  />
                                  <Stack alignItems="center">
                                    {rowData.slab_data.map((slab, slabIdx) => (
                                      <>
                                        <Box
                                          key={slabIdx}
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            margin: "4px",
                                            gap: "4px",
                                            alignItems: "center",
                                          }}
                                        >
                                          <TextField
                                            size="small"
                                            name={`slab_qty_start`}
                                            label="from"
                                            value={slab.slab_qty_start}
                                            onChange={(e) =>
                                              handleSlabChange(e, slabIdx)
                                            }
                                          />
                                          <TextField
                                            size="small"
                                            name={`slab_qty_end`}
                                            label="to"
                                            value={slab.slab_qty_end}
                                            onChange={(e) =>
                                              handleSlabChange(e, slabIdx)
                                            }
                                          />
                                          <TextField
                                            size="small"
                                            name={`slab_sp`}
                                            label=""
                                            value={slab.slab_sp}
                                            onChange={(e) =>
                                              handleSlabChange(e, slabIdx)
                                            }
                                          />
                                        </Box>
                                        <Stack>
                                          {error[
                                            `slab_qty_start_${slabIdx}`
                                          ] && (
                                            <p
                                              style={{
                                                color: "red",
                                                fontSize: "12px",
                                                margin: 0,
                                              }}
                                            >
                                              {
                                                error[
                                                  `slab_qty_start_${slabIdx}`
                                                ]
                                              }
                                            </p>
                                          )}
                                          {error[`slab_qty_end_${slabIdx}`] && (
                                            <p
                                              style={{
                                                color: "red",
                                                fontSize: "12px",
                                                margin: 0,
                                              }}
                                            >
                                              {error[`slab_qty_end_${slabIdx}`]}
                                            </p>
                                          )}
                                        </Stack>
                                      </>
                                    ))}

                                    <Stack direction="row" spacing={1}></Stack>
                                  </Stack>
                                </Stack>
                              ) : (
                                <Stack>
                                  {data?.enable_slab
                                    ? data.slab_data.map((slab, idx) => (
                                        <div key={idx}>
                                          {slab.slab_qty_start} -{" "}
                                          {slab.slab_qty_end} = {slab.slab_sp},
                                        </div>
                                      ))
                                    : ""}
                                </Stack>
                              )}
                            </div>

                            <div style={{ textAlign: "center" }}>
                              {editModeIndex === index ? (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <Radio
                                    checked={!rowData.enable_slab}
                                    onChange={handleRadioChange}
                                    value="option2"
                                  />
                                  <TextField
                                    size="small"
                                    name="ptr"
                                    type="integer"
                                    value={rowData?.ptr || ""}
                                    onChange={handleInputChange}
                                  />
                                </Stack>
                              ) : !data?.enable_slab ? (
                                data.ptr
                              ) : (
                                ""
                              )}
                            </div>
                          </Stack>
                        </TableCell>
                      )}
                      <TableCell align="start" sx={{ minWidth: "100px" }}>
                        {editModeIndex === index ? (
                          <TextField
                            size="small"
                            name="mrp"
                            value={rowData?.mrp || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          <>
                            {switchStatus ? (
                              <>{data?.mrp}</>
                            ) : (
                              <>{data?.sku_mrp}</>
                            )}
                          </>
                        )}
                      </TableCell>
                      {/* <TableCell
                        align="start"
                        colSpan={1}
                        sx={{ maxWidth: "100px" }}
                      >
                         {editModeIndex === index && !(userData?.role === "partner" && data.sku_scope === "All") ? (
                          <TextField
                            size="small"
                            name="inventory"
                            type="integer"
                            value={rowData?.inventory || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          data?.inventory
                        )}
                      </TableCell> */}
                      <TableCell align="start">
                        {editModeIndex === index ? (
                          <TextField
                            size="small"
                            name="scheme"
                            type="text"
                            value={rowData?.scheme || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          data?.scheme
                        )}
                      </TableCell>
                      <TableCell align="start" title={`${data?.pincode} |${data?.office_name}`}>
                        {data?.sku_scope === "All"
                          ? "All"
                          : `${data?.pincode} |${data?.office_name?.length > 10 ? data.office_name.slice(0, 10) + '...' : data.office_name}` || "N/A"}
                      </TableCell>
                      <TableCell align="start">
                        <Switch
                          checked={switchStates[dataIndex]}
                          onChange={() => handleSwitchChange(data, dataIndex)}
                          disabled={userData?.demo}
                          sx={{
                            "& .MuiSwitch-thumb": {
                              backgroundColor: switchStates[dataIndex]
                                ? "#4CAF50"
                                : undefined,
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                              {
                                backgroundColor: "#4CAF50",
                              },
                          }}
                        />
                      </TableCell>
                      
                      <TableCell
                        align="center"
                        onClick={() => handleAutoSuggestChange(index)}
                        
                      >
                        {autoSuggestStates[index] ? (
                          <StarIcon
                            color="#13D2DC"
                            sx={{ color: "#DC1D13", cursor: "pointer" }}
                          />
                        ) : (
                          <StarBorderIcon sx={{ cursor: "pointer" }} />
                        )}
                      </TableCell>
                      <TableCell align="start">
                        {editModeIndex === index ? (
                          <Stack gap={1} flexDirection="row">
                            <SaveOutlinedIcon
                              sx={{
                                color: "#fff",
                                backgroundColor: "#DC1D13",
                                padding: "2px",
                                borderRadius: "6px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleSkuUpdate()}
                            />
                            <CloseIcon
                              sx={{
                                color: "#DC1D13",
                                cursor: "pointer",
                                padding: "2px",
                              }}
                              onClick={() => handleClose()}
                            />
                          </Stack>
                        ) : switchStatus ? (
                          <EditNoteTwoToneIcon
                            fontSize="large"
                            sx={{ color: "#DC1D13", cursor: "pointer" }}
                            onClick={() => handleEditClick(index, data)}
                          />
                        ) : null}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={InventorySKUList?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <Stack sx={{ width: "100%", alignItems: "center" }}>
        <Button
          variant="contained"
          sx={{
            width: "150px",
            marginTop: "12px",
            backgroundColor: "#DC1D13",
            ":hover": { backgroundColor: "#DC1D13" },
          }}
          onClick={handleSubmit}
        >
          Update
        </Button>
      </Stack> */}
      <ToastContainer position="bottom-right" />
    </Stack>
  );
}
