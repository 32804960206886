import React, { useState } from "react";
import styles from "./styles/UserMgtUpdateForm.module.css";
import CreateIcon from "@mui/icons-material/Create";
import { Paper } from "@mui/material";

import Button from "@mui/material/Button";
import {  makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

import Switch from "@mui/material/Switch";


import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BaseImgUrl, ShopBaseImgUrl } from "../../utility/BaseURL";
import { TableconvertDate } from "../Functions/function";
import useRetailerList from "../../hooks/useRetailerList";
import useUpdateRetailer from "../../hooks/useUpdateRetailer";
import useReportedRetailersList from "../../hooks/useReportedRetailersList";
import useUpdateFakeRetailer from "../../hooks/useUpdateFakeRetailer";



const useStyles = makeStyles((theme) => ({
  TextField: {
    color: "black",
  },
}));

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#DC1D13" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

const GrayButton = styled(Button)(({ theme }) => ({
  color: "#404040",
  backgroundColor: "#E5E5E5",
  "&:hover": {
    backgroundColor: "#E5E5E5",
  },
}));

const ApporvalBottomForm = ({ zone }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [token, setToken] = useState("");
  const [form, setForm] = useState({});
  const [formError, setFormError] = useState({});

  const Firsttoken = useSelector((state) => state.Token);
  const userData = useSelector((state) => state?.login?.refreshToken?.user_data);
  const [ZoneList, setZoneList] = useState(["Mumbai", "Bangalore"]);
  const [Zone, setZone] = React.useState("");
  const [edit, setEdit] = useState(false);
  const [RoleList, setRoleList] = useState(["asm", "se", "tsi"]);
  const [Role, setRole] = React.useState("");

  const retailerDetails=useSelector((store)=>store.retailer.selectedReportedRetailer)

  const [StatusList, setStatusList] = useState([
    "active",   
    "inactive",
  ]);
  const [Status, setStatus] = React.useState("");

  const [Updatesuccess, setUpdatesuccess] = useState(false);
  const [obj, setObj] = useState({
    failed: false,
    message: "",
  });
  const [invalidToken, setInvalidToken] = useState(false);

  React.useEffect(() => {
    if (retailerDetails && retailerDetails !== "undefined") {
      setForm(retailerDetails);
      setZone(retailerDetails.zone_name);
      setRole(retailerDetails.role_name);
      setStatus(retailerDetails.status);
    }
  }, [retailerDetails]);

  React.useEffect(() => {
    if (
      Firsttoken &&
      Firsttoken.token &&
      Firsttoken.token !== null &&
      Firsttoken.token.length !== 0 &&
      Firsttoken !== "undefined"
    ) {
      setToken(Firsttoken.token);
    }
  }, [Firsttoken]);

   
  const IsValid = () => {
    let success = true;
    let error = {};

    // console.log(form);

    if (!form.zone_name.trim()) {
      error.zone = "Zone cannot be empty";
      success = false;
    }

    if (!form.name.trim()) {
      error.name = "name cannot be empty";
      success = false;
    }
    if (!form.surname.trim()) {
      error.surname = "surname cannot be empty";
      success = false;
    }
    // if (!form.mobile_no?.toString().trim()) {
    //     error.mobileno = "mobileno cannot be empty";
    //     success = false;
    // }
    // else if (!(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/g).test(form.mobile_no)) {
    //     error.mobileno = "Enter a Valid mobileno Number";
    //     success = false
    // }
    // if (!form.whatsapp_no?.toString().trim()) {
    //     error.whatsappno = "whatsappno cannot be empty";
    //     success = false;
    // }
    // else if (!(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/g).test(form.whatsapp_no)) {
    //     error.whatsappno = "Enter a Valid whatsapp Number";
    //     success = false
    // }

    if (!form.address.trim()) {
      error.address = "address cannot be empty";
      success = false;
    }
    if (!form.lati?.toString().trim()) {
      error.latitude = "latitude cannot be empty";
      success = false;
    }
    if (!form.longi.toString().trim()) {
      error.longitude = "longitude cannot be empty";
      success = false;
    }
    // if (!form.routeid.trim()) {
    //     error.routeid = "routeid cannot be empty";
    //     success = false;
    // }
    // if (!form.timestamp.trim()) {
    //     error.timestamp = "timestamp cannot be empty";
    //     success = false;
    // }

    setFormError(error);
    return success;
  };



  const handleStatusChange = (event) => {
    console.log(event, "event.target.value");
    setForm({ ...form, user_status: event });
  }

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  let param = {
    admin_id: "1",
    retailer_id: form?.user_id,
    route_id: form?.route_id,
    name: form?.name,
    surname: form?.surname,
    mobile_no: form?.mobile_no,
    whatsapp_no: form?.whatsapp_no,
    address: form?.address,
    landmark: form?.landmark,
    shop_name: form?.shop_name,
    capacity: form?.capacity,
    village_id: form?.village_id,
    status: Status,
  };

  // console.log(param, "param", form);

  //   const handleUpdate = async (e) => {
  //     e.preventDefault();
  //     if (IsValid()) {
  //       const data = await dispatch(RetailerUpdateUserMgt(token, param, form));
  //       console.log(data, "data");

  //       if (data.code === 200) {
  //         console.log("here");
  //         toast.success("SuccessFully Updated Retailer");
  //         setEdit(false);
  //       } else {
  //         toast.error(data.message);
  //       }
  //     }
  //   };
   
  
  
  const handleCancel = () => {
    setEdit(false);
    // if (detail && detail !== "undefined") {
    //   setForm(detail);
    //   setZone(detail.zone_name);
    //   setRole(detail.role_name);
    //   setStatus(detail.status);
    // }
  };
  
  React.useEffect(() => {
    if (invalidToken) {
      toast.warning("Invalid Token");
      setInvalidToken(false);
    }
  }, [invalidToken]);

  const handleEdit = () => {
    if(userData?.demo){
      toast.error("You are not authorized to perform this action");
      return;
    }else{
      setEdit(!edit);
    }
    
  };
 
  const updateRetailer = useUpdateFakeRetailer(form);
  const getRetailerList = useReportedRetailersList();
  
  const handleUpdate = async(event) => {
    event.preventDefault(); 
    await updateRetailer();
    await getRetailerList();
  };
  
  // console.log(Status, form);
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form onSubmit={handleUpdate}>
        <div id="ApporvalBottomForm">
          <div
            className={styles.UserMgtUpdateFormContainer}
            style={{ gap: "1.5rem" }}
          >
            {/* {list && list.length !== 0 ? ( */}
            <>
              <div className={styles.UserMgtUpdateFormContainerOne}>
                {/* <div className={styles.UserMgtUpdateFormContainerSectionOne}>
                                    <div className={styles.UserMgtUpdateFormSectionOneTitleContainer}>
                                        <label className={styles.UserMgtUpdateFormSectionOneTitle}>User Details</label>
                                    </div>
   
                                </div> */}

                <div className={styles.UserMgtUpdateFormContainerSectionOneM}>
                  <div
                    className={styles.UserMgtUpdateFormSectionOneTitleContainer}
                  >
                    <label className={styles.UserMgtUpdateFormSectionOneTitle}>
                      {retailerDetails?.shop_name}
                    </label>
                  </div>
                  {/* {!edit ? (
                      <div
                        className={
                          styles.UserMgtUpdateFormSectionOneEditContainer
                        }
                        onClick={handleEdit}
                      >
                        <CreateIcon
                          className={styles.UserMgtUpdateFormSectionOneEditIcon}
                        />
                      </div>
                    ) : null} */}
                </div>

                <div className={styles.UserMgtUpdateFormContainerSectionTwo}>
                  <div className={styles.UserMgtUpdateFormTwoSubSectionOne}>
                    <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          Zone
                        </label>
                      </div>
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          size="small"
                          disabled="true"
                          value={retailerDetails?.zone_name}
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                        />

                        {formError.zone && (
                          <span
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                            }
                          >
                            {formError.zone}
                          </span>
                        )}
                      </div>
                    </div>

                    <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          Retailer ID
                        </label>
                      </div>
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          size="small"
                          disabled="true"
                          value={retailerDetails?.retailer_id}
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                        />
                        {formError.userid && (
                          <span
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                            }
                          >
                            {formError.userid}
                          </span>
                        )}
                      </div>
                    </div>

                    <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          Name
                        </label>
                      </div>
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          size="small"
                          disabled="true"
                          value={form?.first_name}
                          name="first_name"
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                          onChange={handleChange}
                        />
                        {formError.name && (
                          <span
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                            }
                          >
                            {formError.name}
                          </span>
                        )}
                      </div>
                    </div>

                    <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          Surname
                        </label>
                      </div>
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          size="small"
                          value={form?.last_name}
                          disabled="true"
                          name="last_name"
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                          onChange={handleChange}
                        />
                        {formError.surname && (
                          <span
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                            }
                          >
                            {formError.surname}
                          </span>
                        )}
                      </div>
                    </div>

                    <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          Mobile No
                        </label>
                      </div>
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          size="small"
                          value={retailerDetails?.mobile_no}
                          disabled="true"
                          name="mobile_no"
                          onChange={handleChange}
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                        />
                        {formError.mobileno && (
                          <span
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                            }
                          >
                            {formError.mobileno}
                          </span>
                        )}
                      </div>
                    </div>

                    {/* <div
                        className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                      >
                        <div
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListOne
                          }
                        >
                          <label
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                            }
                          >
                            Whatsapp ID
                          </label>
                        </div>
                        <div
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                          }
                        >
                          <input 
                          style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}}
                            variant="outlined"
                            size="small"
                            value={retailerDetails?.mobile_no}
                            disabled={!edit}
                            name="whatsapp_no"
                            onChange={handleChange}
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListField
                            }
                          />
                          {formError.whatsappno && (
                            <span
                              className={
                                styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                              }
                            >
                              {formError.whatsappno}
                            </span>
                          )}
                        </div>
                      </div> */}
                  </div>
                  <div className={styles.UserMgtUpdateFormTwoSubSectionTwo}>
                    {/* <div
                        className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                      >
                        <div
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListOne
                          }
                        >
                          <label
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                            }
                          >
                            Address
                          </label>
                        </div>
                        <div
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                          }
                        >
                          <input
                          style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}}
                            variant="outlined"
                            size="small"
                            value={retailerDetails?.address}
                            disabled="true"
                            multiline
                            rows={3}
                            name="address"
                            onChange={handleChange}
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListField
                            }
                          />
                          {formError.address && (
                            <span
                              className={
                                styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                              }
                            >
                              {formError.address}
                            </span>
                          )}
                        </div>
                      </div> */}

                    {/* {zone.toLowerCase() !== "raipur" ? ( */}
                    <>
                      <div
                        className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                      >
                        <div
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListOne
                          }
                        >
                          <label
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                            }
                          >
                            Latitude
                          </label>
                        </div>
                        <div
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                          }
                        >
                          <input
                            style={{
                              height: "1.8rem",
                              background: "white",
                              border: "1px solid rgba(0, 0, 0, 0.2)",
                            }}
                            variant="outlined"
                            size="small"
                            value={retailerDetails?.lat}
                            disabled="true"
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListField
                            }
                          />
                          {formError.latitude && (
                            <span
                              className={
                                styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                              }
                            >
                              {formError.latitude}
                            </span>
                          )}
                        </div>
                      </div>

                      <div
                        className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                      >
                        <div
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListOne
                          }
                        >
                          <label
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                            }
                          >
                            Longitude
                          </label>
                        </div>
                        <div
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                          }
                        >
                          <input
                            style={{
                              height: "1.8rem",
                              background: "white",
                              border: "1px solid rgba(0, 0, 0, 0.2)",
                            }}
                            variant="outlined"
                            size="small"
                            disabled="true"
                            value={retailerDetails?.lng}
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListField
                            }
                          />
                          {formError.longitude && (
                            <span
                              className={
                                styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                              }
                            >
                              {formError.longitude}
                            </span>
                          )}
                        </div>
                      </div>
                    </>
                    {/* ) : null} */}

                    <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          status
                        </label>
                      </div>
                      {/* {!edit ? ( */}
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          size="small"
                          disabled={!edit}
                          value={form?.user_status === 'inactive_reported' ? 'rejected' : form.user_status}
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                        />
                      </div>
                      {/* ) : ( */}
                      {/* //   <div
                        //     className={
                        //       styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        //     }
                        //   >
                        //     <Box sx={{ minWidth: 120, maxWidth: "100%" }}>
                        //       <FormControl fullWidth>
                        //         <Select
                        //           value={form.user_status}
                        //           onChange={handleStatusChange}
                        //           // size="small"
                        //           sx={{height:"1.8rem"}}
                        //         >
                        //           {StatusList &&
                        //             StatusList.length !== 0 &&
                        //             StatusList !== "undefined" &&
                        //             StatusList !== null &&
                        //             StatusList.map((item, index) => (
                        //               <MenuItem value={item} key={index}>{item}</MenuItem>
                        //             ))}
                        //         </Select>
                        //       </FormControl>
                        //     </Box>
                        //   </div>
                        // )} */}
                    </div>

                    {/* <div
                        className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                      >
                        <div
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListOne
                          }
                        >
                          <label
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                            }
                          >
                            Route ID
                          </label>
                        </div>
                        <div
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                          }
                        >
                          <input 
                          style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}}
                            variant="outlined"
                            size="small"
                            value={form.route_id}
                            disabled={!edit}
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListField
                            }
                          />
                          {formError.routeid && (
                            <span
                              className={
                                styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                              }
                            >
                              {formError.routeid}
                            </span>
                          )}
                        </div>
                      </div> */}

                    <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          Reporting Time
                        </label>
                      </div>
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          size="small"
                          value={
                            retailerDetails?.created_at
                              ? TableconvertDate(retailerDetails?.created_at)
                              : ""
                          }
                          disabled="true"
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                        />
                        {formError.timestamp && (
                          <span
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                            }
                          >
                            {formError.timestamp}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Paper sx={{ flex: "0.9", padding: "5px 10px" }}>
                <div className={styles.UserMgtUpdateFormContainerTwo}>
                  {!edit ? (
                    <div
                      className={
                        styles.UserMgtUpdateFormSectionOneEditContainer
                      }
                      onClick={handleEdit}
                    >
                      <CreateIcon
                        className={styles.UserMgtUpdateFormSectionOneEditIcon}
                      />
                    </div>
                  ) : null}
                  <div
                    className={styles.UserMgtUpdateFormSectionOneTitleContainer}
                  >
                    <label className={styles.UserMgtUpdateFormSectionOneTitle}>
                      Report Details
                    </label>
                  </div>
                  <div
                    className={
                      styles.UserMgtUpdateFormContainerTwoListContainer
                    }
                  >
                    <div className={styles.UserMgtUpdateFormContainerTwoList}>
                      <div
                        className={styles.UserMgtUpdateFormContainerTwoListOne}
                      >
                        {/* <span
                            className={
                              styles.UserMgtUpdateFormContainerTwoListOneIconContainer
                            }
                          >
                            <PersonOutlineIcon
                              className={
                                styles.UserMgtUpdateFormContainerTwoListOneIcon
                              }
                            />
                          </span> */}
                        <label>Reported By</label>
                      </div>
                      <div
                        className={styles.UserMgtUpdateFormContainerTwoListTwo}
                      >
                        {retailerDetails?.first_name}
                        {retailerDetails?.last_name}
                      </div>
                    </div>

                    <div className={styles.UserMgtUpdateFormContainerTwoList}>
                      <div
                        className={styles.UserMgtUpdateFormContainerTwoListOne}
                      >
                        {/* <span
                            className={
                              styles.UserMgtUpdateFormContainerTwoListOneIconContainer
                            }
                          >
                            <CallOutlinedIcon
                              className={
                                styles.UserMgtUpdateFormContainerTwoListOneIcon
                              }
                            />
                          </span> */}
                        <label>Time</label>
                      </div>
                      <div
                        className={styles.UserMgtUpdateFormContainerTwoListTwo}
                      >
                        {retailerDetails?.mobile_no}
                      </div>
                    </div>

                    <div className={styles.UserMgtUpdateFormContainerTwoList}>
                      <div
                        className={styles.UserMgtUpdateFormContainerTwoListOne}
                      >
                        <label>Remark</label>
                      </div>
                      <div
                        className={styles.UserMgtUpdateFormContainerTwoListTwo}
                      >
                        <div
                          className={
                            styles.UserMgtUpdateFormContainerTwoListTwo
                          }
                        >
                          <div
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                            }
                          >
                            <input
                              style={{
                                minHeight: "1.8rem",
                                background: "white",
                                border: "1px solid rgba(0, 0, 0, 0.2)",
                                resize: "vertical",
                              }}
                              variant="outlined"
                              size="small"
                              disabled="true"
                              value={retailerDetails?.remark}
                              className={
                                styles.UserMgtUpdateFormTwoSubSectionOneListField
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.UserMgtUpdateFormContainerTwoList}>
                      <div
                        className={styles.UserMgtUpdateFormContainerTwoListOne}
                      >
                        <label>Disable Retailer</label>
                      </div>
                      <div
                        className={styles.UserMgtUpdateFormContainerTwoListTwo}
                      >
                        <div
                          className={
                            styles.UserMgtUpdateFormContainerTwoListTwo
                          }
                        >
                          {!edit ? (
                            <div
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                            }
                          >
                            <div style={{display:"flex", flexDirection:"row", gap:"10px"}}>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  value="inactive_reported"
                                  disabled
                                  checked={
                                    form.user_status === "inactive_reported"
                                  }
                                  onChange={() =>
                                    handleStatusChange("inactive_reported")
                                    
                                  }
                                />
                                Yes
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  value="active"
                                  disabled
                                  checked={form.user_status === "active"}
                                  onChange={() =>
                                    handleStatusChange("active")
                                  }
                                />
                                No
                              </label>
                            </div>
                            </div>
                          </div>
                          ) : (
                            <div
                              className={
                                styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                              }
                            >
                              <div style={{display:"flex", flexDirection:"row", gap:"10px"}}>
                              <div>
                                <label>
                                  <input
                                    type="radio"
                                    value="inactive_reported"
                                    checked={
                                      form.user_status === "inactive_reported"
                                    }
                                    onChange={() =>
                                      handleStatusChange("inactive_reported")
                                    }
                                  />
                                  Yes
                                </label>
                              </div>
                              <div>
                                <label>
                                  <input
                                    type="radio"
                                    value="active"
                                    checked={form.user_status === "active"}
                                    onChange={() =>
                                      handleStatusChange("active")
                                    }
                                  />
                                  No
                                </label>
                              </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* <div className={styles.UserMgtUpdateFormContainerTwoList}>
                        <div
                          className={
                            styles.UserMgtUpdateFormContainerTwoListOne
                          }
                        >
                          <span
                            className={
                              styles.UserMgtUpdateFormContainerTwoListOneIconContainer
                            }
                          >
                            <EditLocationAltOutlinedIcon
                              className={
                                styles.UserMgtUpdateFormContainerTwoListOneIcon
                              }
                            />
                          </span>
                          <label>Landmark</label>
                        </div>
                        <div
                          className={
                            styles.UserMgtUpdateFormContainerTwoListTwo
                          }
                        >
                          {form.landmark}
                        </div>
                      </div> */}
                  </div>
                  {edit ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <RedButton
                        type="submit"
                        sx={{ height: "1.8rem" }}
                        onClick={handleUpdate}
                      >
                        Update
                      </RedButton>
                      <GrayButton
                        onClick={handleCancel}
                        sx={{ height: "1.8rem" }}
                      >
                        Cancel
                      </GrayButton>
                    </div>
                  ) : null}
                </div>
              </Paper>
            </>
            {/* ) : (
              <label>No Data</label>
            )} */}
          </div>
        </div>
      </form>
    </>
  );
};

export default ApporvalBottomForm;
